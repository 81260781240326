<template>
  <div class="page-wrap purple darken-3">
    <div class="signup-wrapper">
      <base-card>
        <v-card-text class="text-center px-5">
          <v-avatar
            tile
            width="150"
            height="auto"
            class="mb-4 mx-auto"
          >
            <img
              src="@/assets/images/logo.png"
              alt="Servercentralen sign up"
            >
          </v-avatar>
          <h5>{{ $t('userAuth.getStarted') }}</h5>
          <h6 class="text--disabled font-weight-medium">
            {{ $t('userAuth.signUpToUseOurService') }}
          </h6>

          <v-form
            ref="form"
            class="mt-5"
            v-model="validForm"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="py-0"
              >
                <v-text-field
                  v-model="userFirstName"
                  :label="`${$t('common.firstName')}*`"
                  :rules="firstNameRules"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0"
              >
                <v-text-field
                  v-model="userLastName"
                  :label="`${$t('common.lastName')}*`"
                  :rules="lastNameRules"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="userEmail"
                  :label="`${$t('common.email')}*`"
                  type="email"
                  :rules="emailRules"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="userPassword"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isShowPassword ? 'text' : 'password'"
                  :label="`${$t('common.password')}*`"
                  :rules="passwordRules"
                  @click:append="isShowPassword = !isShowPassword"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="userConfirmPassword"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isShowPassword ? 'text' : 'password'"
                  :label="`${$t('common.confirmPassword')}*`"
                  :rules="confirmPasswordRules"
                  @click:append="isShowPassword = !isShowPassword"
                />
              </v-col>
            </v-row>

            <v-alert
              v-if="errorMessage"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="mt-2 text-left"
              dismissible
            >
              {{ errorMessage }}
            </v-alert>

            <v-btn
              block
              color="primary"
              class="mt-4"
              :disabled="!validForm"
              :loading="isLoading"
              @click="submitForm"
            >
              {{ $t("userAuth.signUp") }}
            </v-btn>

            <v-btn text small class="mt-4 font-weight-bold text-subtitle-2" to="/signin" color="purple">
              {{ $t("userAuth.signInToExistingAccount") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  export default {
    name: 'SignUp',

    metaInfo: {
      title: 'SignUp',
    },

    data () {
      return {
        isShowPassword: false,
        validForm: true,
        isLoading: false,
        errorMessage: '',
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userPassword: '',
        userConfirmPassword: '',
        firstNameRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.firstName') }),
        ],
        lastNameRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.lastName') }),
        ],
        emailRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.email') }),
          (v) => /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        passwordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.password') }),
          (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || this.$t('error.invalidPassword'),
        ],
        confirmPasswordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.confirmPassword') }),
          (v) => v === this.userPassword || this.$t('error.confirmPasswordNotMatch'),
        ],
      }
    },

    methods: {
      ...mapActions({
        register: 'register',
      }),

      async submitForm () {
        this.errorMessage = ''

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.isLoading = true
        try {
          const token = await this.register({
            email: this.userEmail,
            password: this.userPassword,
            firstName: this.userFirstName,
            lastName: this.userLastName,
            role: 'user',
          })

          localStorage.setItem('access_token', token)
          localStorage.setItem('role', 'user')
          window.location.href = '/partner-price'
        } catch (error) {
          this.errorMessage = util.getErrorResponse(error)
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.signup-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
</style>
